import React, {useState} from 'react';
import Card from './Card';
import {submitAnswer} from "../api";

const CardList = ({session}) => {
    const options = ['race', 'gender', 'age', 'appearance', 'wealth', 'social status', 'religion', 'national identity', 'education level', 'disability']
    const cards = [
        {
            id: 0, title: 'Bias Relevance', context: '',
            question: '',
            description: '',
            extraDescription: '', role: '', color: 'grey', options: []
        },
        {
            id: 1, title: 'Scenario 1.1: Candidate Selection', context:
                'Imagine, we have trained an AI model to be used for selecting good candidates for a specific job position.',
            question: 'How prominent should the following characteristics be encoded in the AI model?',
            description: '',
            extraDescription: '', role: 'Job position: President', color: '#4e67c8', options: options
        },
        {
            id: 2, title: 'Scenario 1.2: Candidate Selection', context:
                'Imagine, we have trained an AI model to be used for selecting good candidates for a specific job position.',
            question: 'How prominent should the following characteristics be encoded in the AI model?',
            description: '',
            extraDescription: '', role: 'Job position: Pope', color: '#4e67c8', options: options
        },
        {
            id: 3, title: 'Scenario 1.3: Candidate Selection', context:
                'Imagine, we have trained an AI model to be used for selecting good candidates for a specific job position.',
            question: 'How prominent should the following characteristics be encoded in the AI model?',
            description: '',
            extraDescription: '', role: 'Job position: Fashion Model', color: '#4e67c8', options: options
        },
        {
            id: 4, title: 'Scenario 1.4: Candidate Selection', context:
                'Imagine, we have trained an AI model to be used for selecting good candidates for a specific job position.',
            question: 'How prominent should the following characteristics be encoded in the AI model?',
            description: '',
            extraDescription: '', role: 'Job position: Steward', color: '#4e67c8', options: options
        },
        {
            id: 5, title: 'Scenario 1.5: Candidate Selection', context:
                'Imagine, we have trained an AI model to be used for selecting good candidates for a specific job position.',
            question: 'How prominent should the following characteristics be encoded in the AI model?',
            description: '',
            extraDescription: '', role: 'Job position: Military officer', color: '#4e67c8', options: options
        },
        {
            id: 6, title: 'Scenario 2: Healthcare', context:'',
            question: 'How prominent should the following characteristics be encoded in the AI model?',
            description: '',
            extraDescription: 'Imagine you are a doctor using AI to help diagnose a patient with complex symptoms. The patient presents with a combination of fatigue, joint pain, and fever. You use AI to identify potential conditions such as autoimmune disorders or infections.',
            role: '', color: '#5dceaf', options: options
        },
        {
            id: 7, title: 'Scenario 3: Writing Assistant', context: '',
            question: 'How prominent should the following characteristics be encoded in the AI model?',
            description: '',
            extraDescription: 'Imagine you are a writer using AI as your writing assistant. With advanced language algorithms at your fingertips, you generate ideas, refine your drafts, and enhance your storytelling. \n',
            role: '', color: '#ff8021', options: options
        },
        {
            id: 8, title: 'Take-Home Message', context: '',
            question: '',
            description: 'Although bias in AI can introduce discrimination against groups of people of individuals, sometimes it can be beneficial for AI systems to make adapted decisions. ',
            description2:
                'In certain situations, we expect AI \n' +
                'systems to provide equal treatment for different groups or individuals, while in \n' +
                'others, distinctions are crucial, such as in medical \n' +
                'cases or creative tasks.',
            extraDescription: '', role: '', color: 'grey', options: []
        }
    ];

    const [selectedCard, setSelectedCard] = useState(0);
    const [fieldValues, setFieldValues] = useState(Array(options.length + 1).fill(3));
    const [showFinalDiv, setShowFinalDiv] = useState(false);


    const updateFieldValues = (id, value) => {
        setFieldValues(fieldValues.map((val, index) => index === id ? value : val));
    };


    const onNext = async () => {
        // submit answer & reset
        await submitAnswer(session, selectedCard, ...fieldValues);
        setFieldValues(Array(options.length + 1).fill(3));

        // go to next card
        const nextId = selectedCard + 1;
        if (nextId < cards.length) setSelectedCard(nextId);
        else{
            setShowFinalDiv(!showFinalDiv);
        }
        setTimeout(function (){
            window.scrollTo(0, 0);
        }, 300);
    };

    // shortcut to get the current card
    const currentCard = cards[selectedCard];

    return (
        <div>
            {!showFinalDiv && (
                <div style={{
                    marginTop: '10px',
                    padding: '10px',
                    borderRadius: '4px'
                }}>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <Card
                            key={currentCard.id}
                            id={currentCard.id}
                            title={currentCard.title}
                            context={currentCard.context}
                            question={currentCard.question}
                            description={currentCard.description}
                            description2={currentCard.description2}
                            extraDescription={currentCard.extraDescription}
                            role={currentCard.role}
                            color={currentCard.color}
                            options={currentCard.options}
                            onUpdate={updateFieldValues}
                        />
                    </div>
                    {currentCard.id < 8 && (
                    <button type={"button"} className={"btn btn-light"} onClick={onNext} style={{margin: '16px'}}>Next</button>
                        )}
                    {currentCard.id === 8 && (
                        <button type={"button"} className={"btn btn-light"} onClick={onNext} style={{margin: '16px'}}>Finish</button>
                    )}
                </div>
            )}
            {showFinalDiv && (
                <div style={{
                    margin: 'auto',
                }}>
                <h1>THANK YOU!</h1>
                </div>
            )}
        </div>
    );
};

export default CardList;
