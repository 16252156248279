export const getSession = () => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/session`, {method: "POST"})
        .then(response => response.json())
        .then(data => data);
}

export const submitAnswer = (session, question, race, gender, age, appearance, wealth, socialStatus, religion, nationalIdentity, educationLevel, disability, generalAcceptance) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/answer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            sessionId: session.id,
            question: question.toString(),
            race,
            gender,
            age,
            appearance,
            wealth,
            socialStatus,
            religion,
            nationalIdentity,
            educationLevel,
            disability,
            generalAcceptance
        })
    })
}
