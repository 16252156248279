import React from 'react';
import Likert from 'react-likert-scale';

export default ({id, responses, onUpdate}) => {
    const likertOptions = {
        id: id,
        responses: responses,
        onChange: val => {
            onUpdate(id, val.value)
        }
    };
    return (
        <Likert {...likertOptions} />
    )
}
