import logo from './bias.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect, useState} from 'react';
import CardList from "./components/CardList";
import {getSession} from "./api";


function App() {
    const handleClick = () => {
        setShowIntroDiv(!showIntroDiv);
    };

    const handleIntroClick = () => {
        setShowQuestionDiv(!showQuestionDiv);
    };

    const handleFinalClick = () => {
        setShowFinalDiv(!showFinalDiv);
    };


    const [showIntroDiv, setShowIntroDiv] = useState(false);
    const [showQuestionDiv, setShowQuestionDiv] = useState(false);
    const [showFinalDiv, setShowFinalDiv] = useState(false);

    /* We fetch a new session id from the backend as soon as the page loads.
     * With this session id, we can later on connect the different answers
     * to individual participants or survey-runs. This works as long
     * as one cannot restart the survey without reloading the page.  */
    const [session, setSession] = useState(null);
    useEffect(() => {
        getSession().then(data => {
            setSession(data);
            console.log(`Assigned session ID: ${data.id}`);
        });
    }, []);

    return (
        <div className="App" style={{backgroundColor:'white', color:'black'}}>
            <div className="card-header" style={{textAlign:'left', margin:'10px'}}>
                <img width={'100px'} src={'ivia-logo.png'} alt={''}/>
            </div>
            {!showIntroDiv && (
                <header className="App-header" style={{backgroundColor:'white', color:'black'}}>
                    <div>
                        <img width={'60%'} src={logo} alt="logo"/>
                        <h1 style={{color: 'black'}}>
                            Bias in Large Language Models
                        </h1>
                        <button type={"button"} className={"btn btn-light"} onClick={handleClick}>Start</button>
                    </div>
            </header>
            )}

            {showIntroDiv && !showQuestionDiv && (
                    <div className="" style={{width: '100%', height:'100%'}}>
                        <h1>Introduction</h1>
                        <div className="card-body" style={{fontSize: '11pt'}}>
                        <p>You will be asked about the relevance of biases when using an AI system in various scenarios:</p>
                        <br/>
                        <h3  style={{
                            backgroundColor: '#4e67c8',
                            color:'white'
                        }}>Scenario 1: Candidate Selection ~ 3min <br/>
                            <h5>(President, Pope, Fashion model, Steward, Military officer)</h5>
                        </h3>

                        <h3  style={{
                            backgroundColor: '#5dceaf',
                            color:'white'
                        }}>Scenario 2: Healthcare Decision ~ 1min
                        </h3>
                        <h3  style={{
                            backgroundColor: '#ff8021',
                            color:'white'
                        }}>Scenario 3: Writing Assistant ~ 1min
                        </h3>
                            <br/>
                            <p>Respond to all questions truthfully, relying on your immediate instinct.</p>
                        <button type={"button"} className={"btn btn-light"} onClick={handleIntroClick}>Start</button>
                        </div>
                    </div>
                )}
                {showQuestionDiv && (
                    <CardList session={session} />
                )}
        </div>
    );
}

export default App;
