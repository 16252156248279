import React from 'react';
import Likert from "./Likert";

const Card = ({id, title, context, question, description, description2, extraDescription, role, color, options, onUpdate}) => {

    const likertOptions = [
        {value: 1, label: 'Not at all'},
        {value: 2, label: 'Mostly not'},
        {value: 3, label: 'Neutral', checked: true},
        {value: 4, label: 'Mostly yes'},
        {value: 5, label: 'Strongly'}
    ];

    const responses = [
        {value: 1, text: "no"},
        {value: 2, text: ""},
        {value: 3, text: "average", checked: true},
        {value: 4, text: ""},
        {value: 5, text: "strong"}
    ];

    return (
        <div className="card" style={{width: '100%', height: '100%', margin: 'auto'}}>
            <div className="card-header" style={{background: color, color: 'white'}}>
                <h1>{title}</h1>
            </div>
            <div className="card-body" style={{fontSize: '11pt'}}>
                {id === 8 || id === 0 && (
                    <h2>What do you think about biases in AI
                        systems?</h2>
                )}
                <h2>{role}</h2>
                <p className="card-text">{context}</p>
                <p className="card-text">{extraDescription}</p>

                <h5>{question}</h5>
                {options.map((optionName, index) => (
                    <div key={index}>
                        <h5>{optionName}</h5>
                        <div style={{textAlign: 'center', display: 'inline-flex', marginTop:'-5px'}}>
                            <p style={{marginTop:'7px'}}>not at all</p>
                            <Likert id={index} responses={likertOptions} onUpdate={onUpdate}/>
                            <p style={{marginTop:'7px'}}>very prominent</p>
                        </div>
                    </div>
                ))}
                {id < 8 && (
                <div style={{marginTop: '10px'}}>
                    {/* Slider */}
                    <div style={{marginTop: '20px', textAlign:'center'}}>
                        <p><strong>Indicate now on the scale below what is you general acceptance level of AI having
                            biases in their decision-making process:</strong></p>
                        <Likert id={options.length} responses={responses} onUpdate={onUpdate}/>
                    </div>
                </div>
                )}
                {id === 8 && (
                    <div>
                        <h5>{description}</h5>
                        <p>{description2}</p>
                        <h6>Thank you for your participation!</h6>
                        <p><b>Our contact information:</b> kenza.amara@ai.ethz.ch | rita.sevastjanova@inf.ethz.ch</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Card;
